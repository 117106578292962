import React from "react";

export const PrivacyPolicy = ({ name = "BBscore" }) => {
  return (
    <div id="privacy-policy">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center">
            <h2>Política de Privacidade</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-start" style={{ margin: "2%" }}>
          <p>
            A Política de Privacidade do {name} Live Sport Scores App descreve
            como o {name} coleta, usa e divulga informações quando você acessa e
            utiliza nossos serviços por meio de nossos aplicativos móveis e
            site. Esta Política de Privacidade não se aplica a sites, serviços
            ou aplicativos de terceiros que possam ser acessados através dos
            nossos serviços.
          </p>
          <p>
            As informações que coletamos são regidas pela Política de
            Privacidade em vigor no momento da coleta. Podemos atualizar nossa
            Política de Privacidade periodicamente. Se fizermos alterações
            significativas, notificaremos você publicando as mudanças em nosso
            site, através de nossos serviços, ou enviando um e-mail ou outra
            notificação. A data de vigência das alterações será indicada. Ao
            continuar a usar nossos serviços após as alterações entrarem em
            vigor, você concorda com a política atualizada.
          </p>
          <p>
            Nota sobre crianças: Não coletamos intencionalmente informações
            pessoais de crianças menores de 13 anos. Se descobrirmos que
            coletamos inadvertidamente tais informações, tomaremos medidas para
            excluí-las de nossos registros o mais rápido possível.
          </p>
          <p>
            Nota sobre usuários internacionais: Suas informações serão
            transferidas e mantidas em servidores localizados nos Estados Unidos
            (ou em qualquer outro país onde operamos) e processadas lá. Ao nos
            fornecer suas informações, você concorda com essa transferência e
            processamento.
          </p>

          <h3>Informações que Coletamos</h3>
          <p>
            Seu Dispositivo/Computador: Coletamos certas informações do seu
            dispositivo móvel quando você usa nossos serviços, como um
            identificador de dispositivo, configurações do usuário e o sistema
            operacional, bem como dados sobre o uso de nossos serviços pelo seu
            dispositivo. Além disso, quando você visita nosso site, coletamos
            informações enviadas pelo seu navegador, incluindo endereço IP, tipo
            de navegador, páginas de referência/saída e URLs, páginas
            visualizadas e outras informações relacionadas ao uso de nossos
            serviços.
          </p>
          <p>
            Informações Coletadas por Outros: Podemos usar provedores de
            análises para nos ajudar a entender o uso de nossos serviços. Esses
            provedores coletam informações enviadas pelo seu dispositivo móvel
            ou navegador, e o uso dessas informações é regido por suas políticas
            de privacidade aplicáveis.
          </p>

          <h3>Como Usamos as Informações que Coletamos</h3>
          <p>
            Usamos as informações que coletamos para:
            <ul style={{ paddingLeft: 10 }}>
              <li>Fornecer e melhorar nossos serviços</li>
              <li>Responder a consultas</li>
              <li>Personalizar e aprimorar sua experiência</li>
              <li>Monitorar e analisar tendências de uso</li>
              <li>
                Enviar mensagens administrativas relacionadas ao funcionamento e
                uso de nossos serviços
              </li>
              <li>
                Cumprir qualquer outro propósito para o qual as informações
                foram coletadas
              </li>
            </ul>
          </p>

          <h3>Informações que Compartilhamos com Outros</h3>
          <p>
            Compartilharemos suas informações quando você nos instruir a fazer
            isso ou se notificarmos você de que suas informações serão
            compartilhadas de uma maneira específica e você fornecer essas
            informações.
          </p>
          <p>
            Podemos compartilhar suas informações de forma anônima e/ou agregada
            com terceiros para análises, análises de mercado, perfil
            demográfico, pesquisa e outros propósitos semelhantes.
          </p>
          <p>
            Suas informações podem ser acessadas e usadas por nossos provedores
            de serviços que nos auxiliam na operação de nossos serviços. Esses
            provedores podem acessar suas informações apenas na medida
            necessária para realizar serviços em nosso nome e são obrigados a
            não divulgar ou usar as informações para qualquer outro propósito.
          </p>
          <p>
            Podemos transferir ou fornecer informações sobre nossos usuários em
            conexão com uma aquisição, venda de ativos da empresa ou outras
            situações em que as informações do usuário possam ser transferidas
            como parte de nossos ativos comerciais.
          </p>

          <h3>Exclusão de Conta</h3>
          <p>
            Importante:
            <ul style={{ paddingLeft: 10 }}>
              <li>Desinstalar ou excluir o aplicativo não exclui sua conta.</li>
              <li>
                Quando você exclui sua conta, todos os dados associados também
                são excluídos.
              </li>
              <li>
                Se você registrou seu endereço de e-mail em nossos aplicativos,
                não poderá mais usá-lo para fazer login.
              </li>
              <li>
                Mesmo se você reinstalar os aplicativos, não poderá transferir
                sua conta, pois os dados não podem ser restaurados de uma conta
                excluída.
              </li>
            </ul>
          </p>

          <p>
            Para excluir sua conta:
            <ul style={{ paddingLeft: 10 }}>
              <li>
                Faça login &gt; Toque em "Me" &gt; Configurações &gt; Excluir
                Conta
              </li>
            </ul>
          </p>

          <h3>Entrando em Contato Conosco</h3>
          <p>
            Se você tiver alguma dúvida sobre nossa Política de Privacidade,
            entre em contato conosco pelo e-mail contact@{name.toLowerCase()}.ai.
          </p>
        </div>
      </div>
    </div>
  );
};
