import React from "react";

export const PrivacyPolicy = ({ name = "BBscore" }) => {
  return (
    <div id="privacy-policy">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center">
            <h2>隐私政策</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-start" style={{ margin: "2%" }}>
          <p>
            {name} 实时比分应用程序的隐私政策概述了 {name}
            如何在您通过我们的移动应用程序和网站访问和使用我们的服务时收集、使用和披露信息。本隐私政策不适用于可能通过我们的服务访问的第三方网站、服务或应用程序。
          </p>
          <p>
            我们收集的信息受收集时生效的隐私政策的约束。我们可能会定期更新我们的隐私政策。如果我们对本政策进行重大更改，我们会通过在我们的网站上发布更改、通过我们的服务或通过发送电子邮件或其他通知来通知您这些更改。更改生效日期将被指明。继续使用我们的服务即表示您同意更新后的政策。
          </p>
          <p>
            关于儿童的说明: 我们不会故意收集 13
            岁以下儿童的个人信息。如果我们发现我们无意中收集了此类信息，我们将立即从我们的记录中删除它。
          </p>
          <p>
            关于国际用户的说明:
            您的信息将被传输并保存在位于美国（或我们运营的任何其他国家）的服务器上，并在那里处理。通过提供您的信息，您同意此类传输和处理。
          </p>

          <h3>我们收集的信息</h3>
          <p>
            您的设备/计算机:
            我们会在您使用我们的服务时从您的移动设备收集某些信息，如设备标识符、用户设置和操作系统，以及有关您通过设备使用我们服务的数据。此外，当您访问我们的网站时，我们会收集您的网络浏览器发送的信息，包括您的
            IP 地址、浏览器类型、引用/退出页面和
            URL、查看的页面以及与您使用我们服务相关的其他数据。
          </p>
          <p>
            其他人收集的信息:
            我们可能会使用分析提供商来帮助我们了解服务的使用情况。这些提供商将收集您的移动设备或浏览器发送的信息，并且他们对这些信息的使用受其适用隐私政策的约束。
          </p>

          <h3>我们如何使用我们收集的信息</h3>
          <p>
            我们使用收集的信息来：
            <ul style={{ paddingLeft: 10 }}>
              <li>提供和改进我们的服务</li>
              <li>回应查询</li>
              <li>个性化和增强您的体验</li>
              <li>监控和分析使用趋势</li>
              <li>发送与我们的服务相关的管理信息</li>
              <li>实现收集信息的其他目的</li>
            </ul>
          </p>

          <h3>我们与他人共享的信息</h3>
          <p>
            我们会根据您的指示共享您的信息，或者如果我们通知您您的信息将以特定方式共享，并且您相应地提供这些信息。
          </p>
          <p>
            我们可能会以匿名和/或聚合形式与第三方共享您的信息，用于分析、行业分析、人口统计分析、研究和类似目的。
          </p>
          <p>
            我们的服务提供商可能会访问和使用您的信息，这些服务提供商协助我们运营我们的服务。这些提供商只能在必要的范围内访问您的信息，以代表我们执行服务，并有义务不披露或使用这些信息用于其他目的。
          </p>
          <p>
            在公司收购、资产出售或用户信息可能作为我们商业资产的一部分被转移的其他情况下，我们可能会转移或提供有关我们用户的信息。
          </p>

          <h3>账户删除</h3>
          <p>
            重要：
            <ul style={{ paddingLeft: 10 }}>
              <li>卸载或删除应用程序不会删除您的账户。</li>
              <li>当您删除账户时，所有相关数据也将被删除。</li>
              <li>
                如果您在我们的应用程序中注册了电子邮件地址，您将无法再使用它登录。
              </li>
              <li>即使您重新安装应用程序，也无法恢复已删除账户的数据。</li>
            </ul>
          </p>

          <p>
            删除账户的步骤：
            <ul style={{ paddingLeft: 10 }}>
              <li>登录 &gt; 点击“我” &gt; 设置 &gt; 删除账户</li>
            </ul>
          </p>

          <h3>联系我们</h3>
          <p>
            如果您对我们的隐私政策有任何疑问，请通过 contact@{name.toLowerCase()}.ai
            联系我们。
          </p>
        </div>
      </div>
    </div>
  );
};
