import React, { useState, useEffect } from "react";
import { Navigation } from "./components/en/navigation";
import { Header } from "./components/en/header";
import { About } from "./components/en/about";
import { Team } from "./components/en/Team";

import { PrivacyPolicy } from "./components/en/privacy-policy";
import { UserAgreement } from "./components/en/user-agreement";
import { Contact } from "./components/en/contact";
//PT
import { Navigation as NavigationPT } from "./components/pt/navigation";
import { Header as HeaderPT } from "./components/pt/header";
import { About as AboutPT } from "./components/pt/about";
import { Team as TeamPT } from "./components/pt/Team";

import { PrivacyPolicy as PrivacyPolicyPT } from "./components/pt/privacy-policy";
import { UserAgreement as UserAgreementPT } from "./components/pt/user-agreement";
import { Contact as ContactPT } from "./components/pt/contact";

// CN
import { Navigation as NavigationCN } from "./components/cn/navigation";
import { Header as HeaderCN } from "./components/cn/header";
import { About as AboutCN } from "./components/cn/about";
import { Team as TeamCN } from "./components/cn/Team";

import { PrivacyPolicy as PrivacyPolicyCN } from "./components/cn/privacy-policy";
import { UserAgreement as UserAgreementCN } from "./components/cn/user-agreement";
import { Contact as ContactCN } from "./components/cn/contact";
import JsonData from "./data/data.json";
import JsonAboutData from "./data/aboutdata.json";
import JsonDataCN from "./data/dataCN.json";
import JsonDataPT from "./data/dataPT.json";
import SmoothScroll from "smooth-scroll";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { Services } from "./components/en/services";
import { Features } from "./components/en/features";
import { InvertAbout } from "./components/en/invertAbout";
import { AboutUs } from "./components/en/aboutus";

import { TermsAndConditions } from "./components/en/terms-and-conditions";
import { TermsAndConditionsPT } from "./components/pt/terms-and-conditions";
import { TermsAndConditionsCN } from "./components/cn/terms-and-conditions";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [aboutPageData, setAboutPageData] = useState({});
  const [landingPageDataCN, setLandingPageDataCN] = useState({});
  const [landingPageDataPT, setLandingPageDataPT] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
    setAboutPageData(JsonAboutData);
    setLandingPageDataCN(JsonDataCN);
    setLandingPageDataPT(JsonDataPT);
  }, []);

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/" exact>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            {/* <Team data={landingPageData.Team} />
            <Services data={landingPageData.Services} />
            <Gallery data={landingPageData.Gallery} />
            <Testimonials data={landingPageData.Testimonials} /> */}

            <Contact data={landingPageData.Contact} />
          </Route>
          <Route path="/about-us" exact>
            <Navigation />
            <AboutUs data={aboutPageData.AboutUs} />
            <Services data={landingPageData.Services} />
            <About data={aboutPageData.About} />
            <InvertAbout data={aboutPageData.InvertAbout} />
            <Contact data={landingPageData.Contact} />
          </Route>
          <Route path="/en-privacy-policy" exact>
            <PrivacyPolicy />
          </Route>
          <Route path="/en-user-agreement" exact>
            <UserAgreement />
          </Route>
          <Route path="/en-terms-and-conditions" exact>
            <TermsAndConditions />
          </Route>
          <Route path="/okscore/en-term-condition" exact>
            <TermsAndConditions name="OkScore" />
          </Route>
          <Route path="/okscore/en-privacy-policy" exact>
            <PrivacyPolicy name="OkScore" />
          </Route>

          <Route path="/pt" exact>
            <NavigationPT />
            <HeaderPT data={landingPageDataPT.Header} />
            <AboutPT data={landingPageDataPT.About} />
            <TeamPT data={landingPageDataPT.Team} />
            <ContactPT data={landingPageDataPT.Contact} />
          </Route>
          <Route path="/pt-privacy-policy" exact>
            <PrivacyPolicyPT />
          </Route>
          <Route path="/pt-user-agreement" exact>
            <UserAgreementPT />
          </Route>
          <Route path="/pt-terms-and-conditions" exact>
            <TermsAndConditionsPT />
          </Route>
          <Route path="/okscore/pt-term-condition" exact>
            <TermsAndConditionsPT name="OkScore" />
          </Route>
          <Route path="/okscore/pt-privacy-policy" exact>
            <PrivacyPolicyPT name="OkScore" />
          </Route>

          <Route path="/cn" exact>
            <NavigationCN />
            <HeaderCN data={landingPageDataCN.Header} />
            <AboutCN data={landingPageDataCN.About} />
            <TeamCN data={landingPageDataCN.Team} />
            <ContactCN data={landingPageDataCN.Contact} />
          </Route>
          <Route path="/cn-privacy-policy" exact>
            <PrivacyPolicyCN />
          </Route>
          <Route path="/cn-user-agreement" exact>
            <UserAgreementCN />
          </Route>
          <Route path="/cn-terms-and-conditions" exact>
            <TermsAndConditionsCN />
          </Route>
          <Route path="/okscore/cn-term-condition" exact>
            <TermsAndConditionsCN name="OkScore" />
          </Route>
          <Route path="/okscore/cn-privacy-policy" exact>
            <PrivacyPolicyCN name="OkScore" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
