import React from "react";

export const UserAgreement = (props) => {
  return (
    <div id="user-agreement">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center">
            <h2>用户协议</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-start" style={{ margin: "2%" }}>

          <h3>1. 引言</h3>
          <p>
            本隐私政策涉及BBscore的产品（以下简称“我们”），包括我们的网站www.OKscore.ai和我们的移动应用程序。为了提供我们的服务，我们需要收集一些个人数据。本隐私政策解释了我们收集的数据、如何和为什么处理和保存这些数据、如何联系我们以及了解您的隐私权，以及我们对保护您的信息的承诺。
          </p>
          <h4>1.2 数据控制者和所有者</h4>
          <p>
            对于https://OKscore.ai/的所有者，您可以通过电子邮件support@bbscore.ai联系我们。
          </p>

          <h4>1.3 第三方链接</h4>
          <p>
            请注意，我们无法控制我们的合作伙伴网站收集和分享的数据。虽然我们致力于尽可能保护您的隐私和安全，但如果您通过点击我们网站上的插件或链接离开OKscore.ai，他们收集的个人数据将不在我们的控制范围内。我们强烈建议您首先阅读这些网站的隐私政策。
          </p>

          <h3>2. 收集的数据类型 - 我们收集什么数据？</h3>
          <h4>2.1 收集的数据</h4>
          <p>
            我们收集的各种类型的个人数据，包括但不限于电子邮件、Cookies和使用数据。如果我们需要收集其他个人数据，将在本隐私政策的其他部分或通过与数据收集相关的专门说明中进行描述。我们收集的个人数据可以由用户自由提供或自动收集。
          </p>

          <h4>2.2 用户帐户</h4>
          <p>
            为了提升我们的服务，我们设有用户帐户功能。随着隐私的重要性越来越高，我们澄清了有关用户帐户的个人数据的收集和存储方式。个人数据包括能够识别您的信息，如姓名和电子邮件地址。这些数据绝不会被出售或出租给任何人。仅用于满足您的服务请求，如提供应用内统计数据和功能的访问，并执行我们的使用条款。
          </p>

          <h4>2.3 注册</h4>
          <p>
            注册服务时，您同意：(i)提供真实、准确、当前和完整的信息（“注册数据”）；以及(ii)维护并及时更新注册数据。如果您提供虚假、不准确或不完整的信息，或者BBscore合理怀疑您这样做，BBscore可自行决定暂停或终止您的帐户。您对帐户下的所有活动负全责，必须立即通知BBscore任何未经授权的使用或安全漏洞。
          </p>

          <h4>2.4 不收集的数据</h4>
          <p>
            我们不会收集能够正面识别个人的个人或敏感数据。我们也不会收集有关种族、民族、政治观点、宗教或哲学信仰、工会会员、遗传或生物特征数据、健康、性生活或性取向的数据，除非法律要求。
          </p>

          <h3>3. 数据处理的方式和地点 - 我们如何收集数据？</h3>
          <h4>3.1 处理方法</h4>
          <p>
            我们的数据控制者以适当的方式处理用户数据，并采取适当的安全措施防止未经授权的访问、披露、修改或销毁数据。数据处理是使用计算机和IT工具进行的，遵循与所指示的目的相关的组织程序。数据也可以由参与网站运营的某些人员（如管理、销售、营销、法律、系统管理）或外部各方（如技术服务提供商、邮件运营商、托管提供商、IT公司、通信机构）作为数据处理者访问。可随时索取这些各方的更新列表。
          </p>

          <h4>3.2 处理地点</h4>
          <p>
            数据在数据控制者的运营办公室和其他相关方所在的任何地方处理。有关进一步信息，请联系数据控制者。
          </p>

          <h4>3.3 保存时间</h4>
          <p>
            数据保留时间为提供用户请求的服务或本文档中列出的目的所需的时间。用户可以随时请求暂停或删除数据。要删除您的帐户数据，请联系我们support@bbscore.ai。
          </p>

          <h3>4. 收集数据的用途 - 我们为什么收集数据？</h3>
          <p>
            用户数据被收集以提供我们的服务，并用于以下目的：联系用户和显示外部平台的内容。
          </p>

          <h4>4.1 联系用户</h4>
          <p>
            收集的个人数据包括您的电子邮件地址。通过注册邮件列表或新闻通讯，您的电子邮件将被添加到我们的联系列表中，用于发送商业或促销性质的电子邮件。
          </p>

          <h4>4.2 显示外部平台的内容</h4>
          <p>收集的个人数据包括Cookies和使用数据。</p>

          <h3>5. 个人数据的进一步信息</h3>
          <h4>5.1 推送通知</h4>
          <p>
            我们可能会向您发送推送通知，包括警报、声音、图标徽章和与我们的产品相关的信息。您可以通过设备设置控制偏好。
          </p>

          <h4>5.2 唯一设备标识</h4>
          <p>我们的产品可能会存储设备的唯一标识符，仅用于分析或存储偏好。</p>

          <h3>6. 用户行为准则</h3>
          <h4>6.1 合规</h4>
          <p>
            用户必须遵守法律法规，避免非法活动，包括但不限于发布危害国家安全、扰乱社会稳定、违反法律的内容。
          </p>

          <h4>6.2 内容限制</h4>
          <p>
            用户不得发布冒犯性、淫秽、辱骂、诽谤、仇恨、暴力或非法的内容。违反规定可能导致帐户终止和法律行动。
          </p>

          <h4>6.3 法律后果</h4>
          <p>
            违反规定可能导致法律行动、罚款或其他惩罚性措施。BBscore对用户违规行为不承担责任。
          </p>

          <h4>6.4 BBscore的权利</h4>
          <p>
            BBscore保留删除或屏蔽违规内容的权利，并采取措施，包括暂停或终止服务和法律行动。
          </p>

          <h4>6.5 用户责任</h4>
          <p>
            用户对因违规行为对第三方造成的损失或损害负责。BBscore保留寻求赔偿的权利。
          </p>

          <h3>7. 内容责任</h3>
          <p>
            用户对通过服务上传、发布、电子邮件传输或提供的所有内容负责。BBscore无义务预先筛选内容，但保留删除任何令人反感内容的权利。
          </p>

          <h3>8. 内容过滤政策和报告机制</h3>
          <h4>8.1 内容过滤</h4>
          <p>应用程序使用技术检测和过滤不当内容。</p>

          <h4>8.2 报告机制</h4>
          <p>用户可以报告违反EULA的内容。BBscore会审核报告并采取适当措施。</p>

          <h3>9. 阻止用户功能</h3>
          <p>
            应用程序允许用户阻止其他用户，防止互动和内容查看。BBscore保留对滥用此功能的用户采取措施的权利。
          </p>

          <h3>10. 数据收集和处理的附加信息</h3>
          <h4>10.1 法律行动</h4>
          <p>数据控制者可能在法律行动中使用用户数据。</p>

          <h4>10.2 系统日志和维护</h4>
          <p>
            我们可能会收集记录与产品交互的文件（系统日志）用于维护目的。此数据不会永久存储。
          </p>

          <h4>10.3 用户权利</h4>
          <p>
            用户对其个人数据享有权利，可以请求访问、修改和删除。用户访问请求应在30天内完成。
          </p>

          <h4>10.4 更多信息</h4>
          <p>
            可以从数据控制者处请求有关数据收集或处理的进一步详细信息。数据控制者可能更新本隐私政策，用户应检查更改。对政策更改的反对可能导致数据删除。
          </p>

          <h3>11. 法律信息</h3>
          <p>本使用条款最后更新于2024年6月4日。</p>

          <h3>12. 联系方式</h3>
          <p>如有查询或问题，请发送电子邮件至support@bbscore.ai。</p>
        </div>
      </div>
    </div>
  );
};
