import React from "react";

export const AboutUs = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 left-col">
            <div className="about-text">
              <h2>{props.data ? props.data.title : "loading..."}</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="card">
              <div className="icon-container">
                  <img src="img/vision.png" alt="Vision Icon" className="icon"></img>
                  <br></br>
                  <h4>Our Vision</h4>
              </div>
              <div className="content">
                  <p>Our vision is to lead the technological frontier, where AI seamlessly integrates with app development to create intuitive, efficient, and engaging user experiences. We aim to be at the forefront of AI innovation, continuously pushing the boundaries of what's possible in software development.</p>
              </div>
            </div>
            <div className="card">
              <div className="icon-container">
                  <img src="img/mission.png" alt="Mission Icon" className="icon"></img>
                  <br></br>
                  <h4>Mission</h4>
              </div>
              <div className="content">
                  <p>At Okscore.Ai, our mission is to revolutionize app productivity and user stickiness through intelligent, AI-powered solutions. We are committed to delivering cutting-edge technology that empowers businesses and delights users, ensuring our clients stay ahead in an ever-evolving digital landscape. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};  
