import React from "react";

export const UserAgreement = (props) => {
  return (
    <div id="user-agreement">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center">
            <h2>User Agreement</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-start" style={{ margin: "2%" }}>
          <h3>1. Introduction</h3>
          <p>
            This Privacy Policy pertains to the products of BBscore (hereinafter
            referred to as ‘we’, ‘us’, or ‘our’). It encompasses our
            website&nbsp;
            <a href="http://www.bbscore.com" target="_blank" rel="noreferrer">
              www.OKscore.ai
            </a>
            &nbsp;and our mobile applications. To provide our services, we need
            to collect some personal data. This Privacy Policy outlines what
            data we collect, how and why we process and store it, how you can
            contact us and learn about your privacy rights, and our commitment
            to protecting your information.
          </p>
          <h4>1.2 Data Controller and Owner</h4>
          <p>
            For the owner of&nbsp;
            <a href="http://www.bbscore.com" target="_blank" rel="noreferrer">
              https://OKscore.ai/
            </a>
            , you can contact us via email at support@bbscore.ai.
          </p>

          <h4>1.3 Third-Party Links</h4>
          <p>
            Please be aware that we do not control the data collected and shared
            by our partner websites. While we are committed to safeguarding your
            privacy and security, if you leave OKscore.ai by clicking on a
            plugin or link found on our site, the personal data they collect is
            beyond our control. We strongly recommend reading the privacy
            policies of those sites first.
          </p>

          <h3>2. Types of Data Collected – What Do We Collect?</h3>
          <h4>2.1 Collected Data</h4>
          <p>
            We collect various types of personal data, either by itself or
            through third parties, including Email, Cookies, and Usage Data. If
            we need to collect other personal data at any point, it will be
            described in this Privacy Policy or through a dedicated explanation
            text contextual with the data collection. The personal data we
            collect may be freely provided by the user or collected
            automatically.
          </p>

          <h4>2.2 User Account</h4>
          <p>
            To enhance our services, we have a user account feature. With
            privacy being paramount, we clarify how your personal data related
            to the user account is collected and stored. Personal data includes
            information that can identify you, such as your name and email
            address. This data will never be sold or rented to anyone. It is
            used solely to facilitate your service requests, such as providing
            access to in-app stats and features and enforcing our Terms of Use.
          </p>

          <h4>2.3 Registration</h4>
          <p>
            By registering for the Service, you agree to: (i) provide true,
            accurate, current, and complete information about yourself as
            prompted by the registration form (“Registration Data”); and (ii)
            maintain and promptly update the Registration Data. If you provide
            untrue, inaccurate, or incomplete information, or if BBscore
            suspects this, BBscore may suspend or terminate your account at its
            discretion. You are responsible for all activities under your
            account and must notify BBscore immediately of any unauthorized use
            or security breaches.
          </p>

          <h4>2.4 Not-Collected Data</h4>
          <p>
            We do not collect personal or sensitive data that could positively
            identify a person. We also do not collect data on race, ethnicity,
            political opinions, religious or philosophical beliefs, union
            memberships, genetic or biometric data, health, sex life, or sexual
            orientation, unless legally required.
          </p>

          <h3>3. Mode and Place of Processing Data – How Do We Collect It?</h3>
          <h4>3.1 Method of Processing</h4>
          <p>
            Our Data Controller processes user data properly and implements
            appropriate security measures to prevent unauthorized access,
            disclosure, modification, or destruction of data. Data processing is
            carried out using computers and IT-enabled tools, following
            organizational procedures related to the purposes indicated. The
            data may also be accessible to certain persons involved in site
            operation (administration, sales, marketing, legal, system
            administration) or external parties (such as technical service
            providers, mail carriers, hosting providers, IT companies,
            communication agencies) as data processors. The updated list of
            these parties is available upon request.
          </p>

          <h4>3.2 Place of Processing</h4>
          <p>
            Data is processed at the Data Controller’s operating offices and any
            other locations where the involved parties are located. For further
            information, contact the Data Controller.
          </p>

          <h4>3.3 Conservation Time</h4>
          <p>
            Data is retained for the necessary duration to provide the requested
            service or as stated in this document. Users can request data
            suspension or removal at any time. To delete your account data,
            contact us at support@bbscore.ai.
          </p>

          <h3>4. The Use of Collected Data – Why Do We Collect It?</h3>
          <p>
            User data is collected to provide our services and for the following
            purposes: contacting the user and displaying content from external
            platforms.
          </p>

          <h4>4.1 Contacting the User</h4>
          <p>
            Personal Data collected includes your email address. By registering
            for the mailing list or newsletter, your email will be added to our
            contact list for commercial or promotional emails.
          </p>

          <h4>4.2 Displaying Content from External Platforms</h4>
          <p>Personal Data collected includes Cookies and Usage Data.</p>

          <h3>5. Further Information About Personal Data</h3>
          <h4>5.1 Push Notifications</h4>
          <p>
            We may send push notifications to you, including alerts, sounds,
            icon badges, and other information related to our products. You can
            control your preferences via device settings.
          </p>

          <h4>5.2 Unique Device Identification</h4>
          <p>
            Our products may store unique identifiers of your device for
            analytics or storing preferences.
          </p>

          <h3>6. User Code of Conduct</h3>
          <h4>6.1 Legal Compliance</h4>
          <p>
            Users must comply with laws and regulations, avoiding illegal
            activities, including but not limited to publishing content that
            endangers national security, disrupts social stability, or violates
            laws.
          </p>

          <h4>6.2 Content Restrictions</h4>
          <p>
            Users must not post offensive, obscene, abusive, defamatory,
            hateful, violent, or unlawful content. Violations may result in
            account termination and legal action.
          </p>

          <h4>6.3 Legal Consequences</h4>
          <p>
            Violations may lead to legal action, fines, or other punitive
            measures by authorities. BBscore assumes no responsibility for user
            violations.
          </p>

          <h4>6.4 BBscore’s Rights</h4>
          <p>
            BBscore reserves the right to delete or block violating content and
            take measures including service suspension or termination, and legal
            action.
          </p>

          <h4>6.5 User Responsibility</h4>
          <p>
            Users are responsible for any loss or damage to third parties due to
            violations. BBscore reserves the right to seek compensation for
            incurred losses.
          </p>

          <h3>7. Responsibility of Content</h3>
          <p>
            Users are responsible for all content they upload, post, email,
            transmit, or make available through the Service. BBscore has no
            obligation to pre-screen content but reserves the right to remove
            any objectionable content.
          </p>

          <h3>8. Content Filtering Policy and Reporting Mechanisms</h3>
          <h4>8.1 Content Filtering</h4>
          <p>
            The application uses technology to detect and filter inappropriate
            content.
          </p>

          <h4>8.2 Reporting Mechanism</h4>
          <p>
            Users can report content violating the EULA. BBscore reviews reports
            and takes appropriate actions.
          </p>

          <h3>9. Blocking User Functionality</h3>
          <p>
            The application allows users to block others, preventing interaction
            and content viewing. BBscore reserves the right to take action
            against abuse of this feature.
          </p>

          <h3>
            10. Additional Information About Data Collection and Processing
          </h3>
          <h4>10.1 Legal Actions</h4>
          <p>
            User data may be used for legal purposes by the Data Controller in
            court or legal actions.
          </p>

          <h4>10.2 System Logs and Maintenance</h4>
          <p>
            We may collect files recording interactions with our products
            (System Logs) for maintenance purposes. This data is not stored
            permanently.
          </p>

          <h4>10.3 User Rights</h4>
          <p>
            Users have rights regarding their personal data and can request
            access, correction, and deletion. User access requests should be
            completed within 30 days.
          </p>

          <h4>10.4 More Information</h4>
          <p>
            Further details on data collection or processing can be requested
            from the Data Controller. The Data Controller may update this
            Privacy Policy, and users should check for changes. Objections to
            policy changes can result in data erasure.
          </p>

          <h3>11. Legal Information</h3>
          <p>This Terms of Use was last updated on June 4, 2024.</p>

          <h3>12. Contact</h3>
          <p>
            Please email us at support@bbscore.ai for inquiries or questions.
          </p>
        </div>
      </div>
    </div>
  );
};
