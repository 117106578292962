import React from "react";

export const PrivacyPolicy = ({ name = "BBscore" }) => {
  return (
    <div id="privacy-policy">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center">
            <h2>Privacy Policy</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-start" style={{ margin: "2%" }}>
          <p>
            The&nbsp;
            <u>
              <strong>{name}</strong>
            </u>
            &nbsp;Live Sport Scores App Privacy Policy outlines how {name}
            collects, uses, and discloses information when you access and use
            our services via our mobile applications and website. This Privacy
            Policy does not apply to third-party websites, services, or
            applications that may be accessible through our services.
          </p>

          <p>
            The information we collect is governed by the Privacy Policy in
            effect at the time the information is collected. We may update
            our&nbsp;
            <u>
              <strong>Privacy Policy</strong>
            </u>
            &nbsp;periodically. If we make significant changes, we will notify
            you by posting the changes on our website, through our services, or
            by sending an email or other notification. The effective date of the
            changes will be indicated. By continuing to use our services after
            the changes take effect, you agree to the updated policy.
          </p>
          <p>
            <strong>Note Regarding Children:&nbsp;</strong>
            We do not knowingly collect personal information from children under
            the age of 13. If we discover that we have inadvertently collected
            such information, we will promptly delete it from our records.
          </p>
          <p>
            <strong>Note Regarding International Users:&nbsp;</strong>Your
            information will be transferred to and maintained on servers in the
            United States (or any other country where we operate) and processed
            there. By providing your information, you consent to this transfer
            and processing.
          </p>

          <h3>Information We Collect</h3>
          <p>
            <strong>Your Device/Computer:&nbsp;</strong>We collect certain
            information from your mobile device when you use our services, such
            as a device identifier, user settings, and the operating system, as
            well as data on your use of our services. Additionally, when you
            visit our website, we collect information sent by your web browser,
            including your IP address, browser type, referring/exit pages and
            URLs, pages viewed, and other data related to your usage.
          </p>

          <p>
            <strong>Information Collected by Others:&nbsp;</strong>
            We may use analytics providers to help us understand the use of our
            services. These providers collect information sent by your mobile
            device or browser, and their use of this information is governed by
            their privacy policies.
          </p>

          <h3>How We Use the Information We Collect</h3>
          <p>
            We use the information we collect to:
            <ul style={{ paddingLeft: 10 }}>
              <li>Provide and improve our services</li>
              <li>Respond to inquiries</li>
              <li>Personalize and enhance your experience</li>
              <li>Monitor and analyze usage trends</li>
              <li>Send administrative messages related to our services</li>
              <li>
                Fulfill any other purpose for which the information was
                collected
              </li>
            </ul>
          </p>

          <h3>Information We Share With Others</h3>
          <p>
            We will share your information when you instruct us to do so or if
            we notify you that your information will be shared in a specific
            manner, and you provide the information accordingly.
          </p>
          <p>
            We may share your information in anonymous and/or aggregated form
            with third parties for analytics, industry analysis, demographic
            profiling, research, and similar purposes.
          </p>
          <p>
            Your information may be accessed and used by our service providers
            who assist us in operating our services. These providers may access
            your information only to the extent necessary to perform services on
            our behalf and are obligated not to disclose or use the information
            for any other purposes.
          </p>

          <p>
            We may transfer or provide information about our users in connection
            with a company acquisition, sale of assets, or other situations
            where user information may be transferred as part of our business
            assets.
          </p>

          <h3>Account Deletion</h3>
          <p>
            Important:
            <ul style={{ paddingLeft: 10 }}>
              <li>
                Uninstalling or deleting the app will not delete your account.
              </li>
              <li>
                When you delete your account, all associated data will also be
                deleted.
              </li>
              <li>
                If you registered your email address with our apps, you will no
                longer be able to use it to log in.
              </li>
              <li>
                Even if you reinstall the apps, you cannot transfer your
                account, as data cannot be restored from a deleted account.
              </li>
            </ul>
          </p>

          <p>
            To delete your account:
            <ol>
              <li>Log in &gt; Tap "Me" &gt; Settings &gt; Delete Account</li>
            </ol>
          </p>

          <h3>Contacting Us</h3>
          <p>
            If you have any questions about our Privacy Policy, please contact
            us at contact@{name.toLowerCase()}.ai.
          </p>
        </div>
      </div>
    </div>
  );
};
