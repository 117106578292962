import React from "react";
import "../../App.css";

export const TermsAndConditionsCN = ({ name = "BBscore" }) => {
  return (
    <div id="term-and-conditions">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center">
            <h2>条款和条件</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-start" style={{ margin: "2%" }}>
          <p style={{ fontWeight: "bold" }}>
            在使用我们的平台之前，请花一点时间阅读以下条款和条件。
          </p>

          <h4>总结</h4>
          <p>
            这些条款概述了使用我们所有或部分网站和/或应用程序——统称为“平台”——的指南。您通过访问或使用我们的平台，承认您将遵守这些条款。
          </p>

          <h4>联系方式</h4>
          <p>
            访问 {name} 联系页面或发送电子邮件至 contact@{name.toLowerCase()}.ai
            提出任何问题。
          </p>

          <h4>条件接受</h4>
          <p>
            通过使用我们的平台，您承认您已阅读这些条款。如果您不同意这些条款，则禁止使用我们的平台。
          </p>

          <h4>补充条款</h4>
          <p>
            这些条款提到了以下内容：
            <ul style={{ paddingLeft: 20 }}>
              <li>
                <strong>
                  <u>隐私政策：</u>
                </strong>
                &nbsp;我们的数据处理政策在隐私通知中进行了描述。
              </li>
              <li>
                <strong>
                  <u>用户协议：</u>
                </strong>
                &nbsp;关于我们与平台之间的协议的详细信息。
              </li>
            </ul>
          </p>

          <h4>变更</h4>
          <p>
            我们可能会不时更新这些条款。为了保持最新，请每次使用我们的平台时查看这些条款。
          </p>

          <h4>平台修改</h4>
          <p>我们可能会根据用户需求和服务改进对我们的平台进行更新。</p>

          <h4>平台可访问性</h4>
          <p>
            尽管我们努力保持平台的可用性，但有时我们需要限制或暂停对平台的访问以满足操作需求。
          </p>

          <h4>材料使用</h4>
          <p>
            我们平台上的所有内容均受知识产权法律保护。未经适当授权，您不得修改、复制或用于商业目的。
          </p>

          <h4>信息准确性</h4>
          <p>
            我们平台提供的信息仅供一般参考。我们不保证其准确性或及时性，特别是关于体育比分的信息。
          </p>

          <h4>第三方内容</h4>
          <p>
            我们的平台可能包含第三方的内容和链接。我们对这些内容或由此产生的任何损害不承担责任。
          </p>

          <h4>责任</h4>
          <p>
            我们不对因使用我们的平台或服务中断而产生的任何直接或间接损失负责。
          </p>

          <h4>不可抗力</h4>
          <p>我们不对因超出我们控制范围的事件而导致的任何未履行负责。</p>

          <h4>个人信息</h4>
          <p>我们按照我们的隐私政策管理个人信息。</p>

          <h4>安全</h4>
          <p>我们无法保证完全防护病毒或漏洞。用户对自己系统的安全负责。</p>

          <h4>链接政策</h4>
          <p>
            您可以创建指向我们平台的链接，但必须负责任地进行。未经授权的链接或滥用是不允许的。
          </p>

          <h4>禁止使用</h4>
          <p>用户必须避免在我们的平台上进行任何非法或有害的活动。</p>

          <h4>违反条款的后果</h4>
          <p>违反这些条款可能导致账户暂停、法律行动或通知执法机关。</p>

          <h4>争议解决</h4>
          <p>这些条款受英国法律管辖，争议应在英格兰和威尔士的法院解决。</p>

          <h4>商标</h4>
          <p>
            {name} 和相关徽标是 {name} 的注册商标。未经授权的使用是不允许的。
          </p>
        </div>
      </div>
    </div>
  );
};
