import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Core Value</h2>
        </div>
        <div className="row">
        {props.data
          ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className={i >= 3 ? "col-md-6" : "col-md-4"}>
                <img src={d.icon} className="img-responsive-core-features" alt="" />
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
          : "loading"}
        </div>
      </div>
    </div>
  );
};
