// import { useState } from "react";
// import emailjs from "emailjs-com";
import React from "react";
import { Link } from "react-router-dom";

export const Contact = (props) => {
  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <p>
            Copyright &copy; 2024 ,{" "}
            <a href="https://okscore.ai/" rel="nofollow">
              OkScore.ai ,
            </a>
            All Rights Reserved
          </p>
          <p>
            <a href="/en-terms-and-conditions">Terms and Conditions</a> |&nbsp;
            <a href="/en-user-agreement">User Agreements</a> |&nbsp;
            <a href="/en-privacy-policy">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  );
};
