import React from "react";

export const TermsAndConditionsPT = ({ name = "BBscore" }) => {
  return (
    <div id="term-and-conditions">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center">
            <h2>Termos e Condições</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-start" style={{ margin: "2%" }}>
          <p style={{ fontWeight: "bold" }}>
            Antes de usar nossas plataformas, reserve um momento para ler os
            termos e condições abaixo.
          </p>

          <h4>Resumo</h4>
          <p>
            As diretrizes para usar qualquer um ou todos os nossos sites e/ou
            aplicativos—coletivamente, nossas "Plataformas"—estão descritas
            nestes termos. Ao acessar ou usar nossas plataformas, você reconhece
            que concorda com esses termos.
          </p>

          <h4>Detalhes de Contato</h4>
          <p>
            Visite {name} Contact ou envie um e-mail para contact@{name.toLowerCase()}.ai com
            quaisquer perguntas.
          </p>

          <h4>Aceitação das Condições</h4>
          <p>
            Você reconhece que leu estes termos ao usar nossas plataformas. O
            uso das nossas Plataformas é proibido se você não concordar com
            estes termos.
          </p>

          <h4>Termos Suplementares</h4>
          <p>
            Os seguintes são mencionados por estes termos:
            <ul style={{ paddingLeft: 20 }}>
              <li>
                <strong>
                  <u>Políticas de Privacidade: </u>
                </strong>
                Nossas políticas de tratamento de dados estão descritas no nosso
                aviso de privacidade.
              </li>
              <li>
                <strong>
                  <u>Contrato do Usuário: </u>
                </strong>
                Detalhes sobre o contrato que temos com nossas plataformas.
              </li>
            </ul>
          </p>

          <h4>Alterações</h4>
          <p>
            Podemos atualizar estes termos de tempos em tempos. Para se manter
            atualizado, revise-os toda vez que usar nossas plataformas.
          </p>

          <h4>Modificações da Plataforma</h4>
          <p>
            Podemos fazer atualizações em nossas Plataformas em resposta às
            demandas dos usuários e melhorias no serviço.
          </p>

          <h4>Acessibilidade da Plataforma</h4>
          <p>
            Embora trabalhemos arduamente para manter a disponibilidade, há
            situações em que temos que limitar ou suspender o acesso às nossas
            Plataformas por necessidades operacionais.
          </p>

          <h4>Uso de Material</h4>
          <p>
            Todo o conteúdo em nossas Plataformas está protegido por leis de
            propriedade intelectual. Você não pode alterar, reproduzir ou usar
            para fins comerciais sem a devida autorização.
          </p>

          <h4>Precisão das Informações</h4>
          <p>
            As informações fornecidas em nossas Plataformas são apenas para fins
            gerais. Não garantimos sua precisão ou atualidade, especialmente em
            relação às pontuações esportivas.
          </p>

          <h4>Conteúdo de Terceiros</h4>
          <p>
            Nossas Plataformas podem apresentar conteúdo e links de terceiros.
            Não somos responsáveis por esse conteúdo ou por quaisquer danos
            resultantes.
          </p>

          <h4>Responsabilidade</h4>
          <p>
            Não somos responsáveis por quaisquer perdas diretas ou indiretas
            decorrentes do uso de nossas Plataformas ou de interrupções no
            serviço.
          </p>

          <h4>Força Maior</h4>
          <p>
            Não somos responsáveis por qualquer não cumprimento devido a eventos
            além do nosso controle.
          </p>

          <h4>Informações Pessoais</h4>
          <p>
            Gerenciamos informações pessoais de acordo com nossas Políticas de
            Privacidade.
          </p>

          <h4>Segurança</h4>
          <p>
            Não podemos garantir proteção completa contra vírus ou bugs. Os
            usuários são responsáveis pela segurança de seus próprios sistemas.
          </p>

          <h4>Políticas de Link</h4>
          <p>
            Você está autorizado a criar links para nossas Plataformas, desde
            que seja feito de maneira responsável. Links não autorizados ou mau
            uso não são permitidos.
          </p>

          <h4>Usos Proibidos</h4>
          <p>
            Os usuários devem se abster de qualquer atividade ilegal ou
            prejudicial em nossas Plataformas.
          </p>

          <h4>Consequências da Violação</h4>
          <p>
            A violação destes termos pode resultar em suspensão da conta, ação
            legal ou notificação às autoridades.
          </p>

          <h4>Resolução de Disputas</h4>
          <p>
            Estes termos são regidos pela lei inglesa, e as disputas devem ser
            resolvidas nos tribunais da Inglaterra e País de Gales.
          </p>

          <h4>Marcas Registradas</h4>
          <p>
            {name} e logotipos relacionados são marcas registradas da {name}. O
            uso não autorizado não é permitido.
          </p>
        </div>
      </div>
    </div>
  );
};
