import React from "react";

export const TermsAndConditions = ({ name = "BBscore" }) => {
  return (
    <div id="term-and-conditions">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center">
            <h2>Terms and Conditions</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-start" style={{ margin: "2%" }}>
          <p>
            Before using our platforms, please take a moment to read the terms
            and conditions listed below.
          </p>

          <h4>Summary</h4>
          <p>
            The guidelines for using any or all of our websites and/or
            applications—collectively, our "Platforms"—are outlined in these
            terms. You acknowledge that you will abide by these terms by
            accessing or using our platforms.
          </p>

          <h4>Contact Details</h4>
          <p>
            Visit&nbsp;
            <u>
              <strong>{name}</strong>
            </u>
            &nbsp;Contact or send us an email at contact@{name.toLowerCase()}.ai with any
            questions.
          </p>

          <h4>Acceptance of the Conditions</h4>
          <p>
            You acknowledge that you have read these terms by using our
            platforms. Use of our Platforms is prohibited if you disagree with
            these terms.
          </p>

          <h4>Supplementary Terms</h4>
          <p>The following are mentioned by these terms:</p>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <u>
                <strong>Privacy Policies:</strong>
              </u>
              &nbsp;Our data handling policies are described in our privacy
              notice.
            </li>
            <li>
              <u>
                <strong>User Agreement:</strong>
              </u>
              &nbsp;Details regarding the agreement we have with our platforms.
            </li>
          </ul>

          <h4>Changes</h4>
          <p>
            We may from time to time update these terms. To stay current, review
            them every time you use our platforms.
          </p>

          <h4>Platform Modifications</h4>
          <p>
            We might make updates to our Platforms in response to user demands
            and service improvements.
          </p>

          <h4>Platform Accessibility</h4>
          <p>
            Even though we work hard to maintain availability, there are
            situations when we have to limit or suspend access to our Platforms
            for operational needs.
          </p>

          <h4>Material Use</h4>
          <p>
            All content on our Platforms is protected under intellectual
            property laws. You may not alter, reproduce, or use it for
            commercial purposes without proper authorization.
          </p>

          <h4>Information Accuracy</h4>
          <p>
            The information provided on our Platforms is for general purposes
            only. We do not guarantee its accuracy or timeliness, particularly
            concerning sports scores.
          </p>

          <h4>Third-Party Content</h4>
          <p>
            Our Platforms may feature content and links from third parties. We
            are not responsible for such content or any resulting damages.
          </p>

          <h4>Liability</h4>
          <p>
            We are not liable for any direct or indirect losses arising from the
            use of our Platforms or any service interruptions.
          </p>

          <h4>Force Majeure</h4>
          <p>
            We are not responsible for any non-performance due to events beyond
            our control.
          </p>

          <h4>Personal Information</h4>
          <p>
            We manage personal information according to our&nbsp;
            <strong>
              <u>Privacy Policies</u>
            </strong>
            .
          </p>

          <h4>Security</h4>
          <p>
            We cannot guarantee complete protection against viruses or bugs.
            Users are responsible for the security of their own systems.
          </p>

          <h4>Linking Policies</h4>
          <p>
            You are permitted to link to our Platforms, provided it is done
            responsibly. Unauthorized linking or misuse is not allowed.
          </p>

          <h4>Prohibited Uses</h4>
          <p>
            Users must refrain from any illegal or harmful activities on our
            Platforms.
          </p>

          <h4>Consequences of Breach</h4>
          <p>
            Violation of these terms may result in account suspension, legal
            action, or notification to law enforcement authorities.
          </p>

          <h4>Dispute Resolution</h4>
          <p>
            These terms are governed by English law, and disputes are to be
            resolved in the courts of England and Wales.
          </p>

          <h4>Trademarks</h4>
          <p>
            {name} and related logos are registered trademarks of {name}.
            Unauthorized use is not permitted.
          </p>
        </div>
      </div>
    </div>
  );
};
