import React from "react";

export const UserAgreement = (props) => {
  return (
    <div id="user-agreement">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center">
            <h2>Termo de Acordo do Usuário</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-start" style={{ margin: "2%" }}>

          <h3>1. Introdução</h3>
          <p>
            Esta Política de Privacidade diz respeito aos produtos da BBscore
            (doravante referidos como ‘nós’, ‘nosso’, ou ‘nossa’), incluindo
            nosso site&nbsp;
            <a href="http://www.bbscore.com" target="_blank" rel="noreferrer">
              www.OKscore.ai
            </a>
            &nbsp;e nossos aplicativos móveis. Para fornecer nossos serviços,
            precisamos coletar alguns dados pessoais. Esta Política de
            Privacidade descreve quais dados coletamos, como e por que
            processamos e armazenamos esses dados, como você pode nos contatar e
            conhecer seus direitos de privacidade, bem como nosso compromisso em
            proteger suas informações.
          </p>
          <h4>1.2 Controlador e Proprietário de Dados</h4>
          <p>
            Para o proprietário de&nbsp;
            <a href="https://bbscore.com/" target="_blank" rel="noreferrer">
              https://OKscore.ai/
            </a>
            , você pode nos contatar por e-mail em support@bbscore.ai.
          </p>

          <h4>1.3 Links de Terceiros</h4>
          <p>
            Esteja ciente de que não controlamos os dados coletados e
            compartilhados por nossos sites parceiros. Embora estejamos
            comprometidos em proteger sua privacidade e segurança, se você sair
            de OKscore.ai clicando em um plugin ou link encontrado em nosso
            site, os dados pessoais que eles coletam estarão fora do nosso
            controle. Recomendamos fortemente que você leia primeiro as
            políticas de privacidade desses sites.
          </p>

          <h3>2. Tipos de Dados Coletados – O Que Coletamos?</h3>
          <h4>2.1 Dados Coletados</h4>
          <p>
            Coletamos vários tipos de dados pessoais, seja por si só ou através
            de terceiros, incluindo Email, Cookies e Dados de Uso. Se
            precisarmos coletar outros dados pessoais em algum momento, isso
            será descrito nesta Política de Privacidade ou através de um texto
            explicativo dedicado contextual com a coleta de dados. Os dados
            pessoais que coletamos podem ser fornecidos livremente pelo usuário
            ou coletados automaticamente.
          </p>

          <h4>2.2 Conta de Usuário</h4>
          <p>
            Para aprimorar nossos serviços, temos uma funcionalidade de conta de
            usuário. Com a privacidade sendo fundamental, esclarecemos como seus
            dados pessoais relacionados à conta de usuário são coletados e
            armazenados. Dados pessoais incluem informações que podem
            identificá-lo, como seu nome e endereço de e-mail. Esses dados nunca
            serão vendidos ou alugados a ninguém. São usados apenas para
            facilitar seus pedidos de serviço, como fornecer acesso a
            estatísticas e recursos do aplicativo, e para aplicar nossos Termos
            de Uso.
          </p>

          <h4>2.3 Registro</h4>
          <p>
            Ao se registrar para o Serviço, você concorda em: (i) fornecer
            informações verdadeiras, precisas, atuais e completas sobre você
            conforme solicitado pelo formulário de registro do Serviço (“Dados
            de Registro”); e (ii) manter e atualizar prontamente os Dados de
            Registro. Se você fornecer informações falsas, imprecisas ou
            incompletas, ou se a BBscore suspeitar disso, a BBscore pode
            suspender ou encerrar sua conta a seu critério. Você é responsável
            por todas as atividades sob sua conta e deve notificar imediatamente
            a BBscore sobre qualquer uso não autorizado ou violações de
            segurança.
          </p>

          <h4>2.4 Dados Não Coletados</h4>
          <p>
            Não coletamos dados pessoais ou sensíveis que possam identificar
            positivamente uma pessoa. Também não coletamos dados sobre raça,
            etnia, opiniões políticas, crenças religiosas ou filosóficas,
            filiações sindicais, dados genéticos ou biométricos, saúde, vida
            sexual ou orientação sexual, a menos que exigido por lei.
          </p>

          <h3>3. Modo e Local de Processamento dos Dados – Como Coletamos?</h3>
          <h4>3.1 Método de Processamento</h4>
          <p>
            Nosso Controlador de Dados processa os dados do usuário de maneira
            adequada e implementa medidas de segurança apropriadas para prevenir
            acesso não autorizado, divulgação, modificação ou destruição dos
            dados. O processamento de dados é realizado usando computadores e
            ferramentas de TI, seguindo procedimentos organizacionais
            relacionados aos fins indicados. Os dados também podem ser
            acessíveis a certas pessoas envolvidas na operação do site
            (administração, vendas, marketing, jurídico, administração de
            sistemas) ou partes externas (como provedores de serviços técnicos,
            operadores de correio, provedores de hospedagem, empresas de TI,
            agências de comunicação) como processadores de dados. A lista
            atualizada dessas partes está disponível mediante solicitação.
          </p>

          <h4>3.2 Local de Processamento</h4>
          <p>
            Os dados são processados nos escritórios operacionais do Controlador
            de Dados e em quaisquer outros locais onde as partes envolvidas
            estejam localizadas. Para mais informações, entre em contato com o
            Controlador de Dados.
          </p>

          <h4>3.3 Tempo de Conservação</h4>
          <p>
            Os dados são mantidos pelo tempo necessário para fornecer o serviço
            solicitado pelo usuário ou conforme estabelecido neste documento. Os
            usuários podem solicitar a suspensão ou remoção dos dados a qualquer
            momento. Para excluir os dados da sua conta, entre em contato
            conosco em support@bbscore.ai.
          </p>

          <h3>4. Uso dos Dados Coletados – Por Que Coletamos?</h3>
          <p>
            Os dados do usuário são coletados para permitir a prestação dos
            nossos serviços, bem como para os seguintes fins: contato com o
            usuário e exibição de conteúdo de plataformas externas.
          </p>

          <h4>4.1 Contato com o Usuário</h4>
          <p>
            Os dados pessoais coletados incluem seu endereço de e-mail. Ao se
            registrar em nossa lista de e-mails ou newsletter, seu e-mail será
            adicionado à nossa lista de contatos para envio de mensagens
            comerciais ou promocionais.
          </p>

          <h4>4.2 Exibição de Conteúdo de Plataformas Externas</h4>
          <p>Os dados pessoais coletados incluem Cookies e Dados de Uso.</p>

          <h3>5. Mais Informações Sobre Dados Pessoais</h3>
          <h4>5.1 Notificações Push</h4>
          <p>
            Podemos enviar notificações push, incluindo alertas, sons, ícones e
            outras informações relacionadas ao uso de nossos produtos. Você pode
            escolher permitir ou rejeitar notificações push nas configurações do
            dispositivo.
          </p>

          <h4>5.2 Identificação Única do Dispositivo</h4>
          <p>
            Nossos produtos podem armazenar identificadores únicos do seu
            dispositivo para fins analíticos ou de armazenamento de
            preferências.
          </p>

          <h3>6. Código de Conduta do Usuário</h3>
          <h4>6.1 Conformidade</h4>
          <p>
            Os usuários devem cumprir as leis e regulamentos e evitar atividades
            ilegais, incluindo, mas não se limitando a, publicação de conteúdo
            que ponha em risco a segurança nacional, desestabilize a ordem
            social, viole leis ou regulamentos.
          </p>

          <h4>6.2 Restrição de Conteúdo</h4>
          <p>
            Os usuários não devem publicar conteúdo ofensivo, obsceno, abusivo,
            difamatório, odioso, violento ou ilegal. Qualquer violação pode
            resultar na rescisão da conta e em ações legais.
          </p>

          <h4>6.3 Consequências Legais</h4>
          <p>
            Violações podem resultar em ações legais, multas ou outras medidas
            punitivas. BBscore não se responsabiliza por violações dos usuários.
          </p>

          <h4>6.4 Direitos da BBscore</h4>
          <p>
            BBscore reserva-se o direito de remover ou bloquear conteúdo
            infrator e tomar medidas, incluindo suspensão ou rescisão do serviço
            e ações legais.
          </p>

          <h4>6.5 Responsabilidade do Usuário</h4>
          <p>
            Os usuários são responsáveis por perdas ou danos causados a
            terceiros por suas violações. BBscore reserva-se o direito de buscar
            compensação.
          </p>

          <h3>7. Responsabilidade pelo Conteúdo</h3>
          <p>
            Os usuários são responsáveis por todo o conteúdo que carregam,
            postam, transmitem ou disponibilizam através do serviço. BBscore não
            tem obrigação de pré-selecionar o conteúdo, mas reserva-se o direito
            de remover qualquer conteúdo ofensivo.
          </p>

          <h3>
            8. Política de Filtragem de Conteúdo e Mecanismos de Relatório
          </h3>
          <h4>8.1 Filtragem de Conteúdo</h4>
          <p>
            O aplicativo utiliza tecnologia para detectar e filtrar conteúdo
            inadequado.
          </p>

          <h4>8.2 Mecanismos de Relatório</h4>
          <p>
            Os usuários podem relatar conteúdo que viole os termos do EULA.
            BBscore revisará os relatórios e tomará as medidas apropriadas.
          </p>

          <h3>9. Funcionalidade de Bloqueio de Usuário</h3>
          <p>
            O aplicativo permite que os usuários bloqueiem outros usuários para
            impedir interações e visualização de conteúdo. BBscore reserva-se o
            direito de tomar medidas contra usuários que abusem dessa
            funcionalidade.
          </p>

          <h3>
            10. Informações Adicionais Sobre Coleta e Processamento de Dados
          </h3>
          <h4>10.1 Ações Legais</h4>
          <p>
            Os dados do usuário podem ser utilizados em ações legais pelo
            Controlador de Dados.
          </p>

          <h4>10.2 Logs do Sistema e Manutenção</h4>
          <p>
            Podemos coletar registros de interação com nossos produtos (Logs do
            Sistema) para fins de manutenção. Esses dados não serão armazenados
            permanentemente.
          </p>

          <h4>10.3 Direitos do Usuário</h4>
          <p>
            Os usuários têm o direito de acessar, modificar e excluir seus dados
            pessoais. Solicitações de acesso devem ser concluídas em 30 dias.
          </p>

          <h4>10.4 Mais Informações</h4>
          <p>
            Mais detalhes sobre coleta ou processamento de dados podem ser
            solicitados ao Controlador de Dados. O Controlador de Dados pode
            atualizar esta Política de Privacidade, e os usuários devem
            verificar as alterações. A objeção às alterações pode resultar na
            exclusão dos dados.
          </p>

          <h3>11. Informações Legais</h3>
          <p>
            Este Termos de Uso foi atualizado pela última vez em 4 de junho de
            2024.
          </p>

          <h3>12. Contato</h3>
          <p>
            Envie um e-mail para support@bbscore.ai se tiver dúvidas ou
            perguntas.
          </p>
        </div>
      </div>
    </div>
  );
};
