import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
          OkScore.ai
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/" className="page-scroll">
                Home
              </a>
            </li>
            <li>
              <a href="/about-us" className="page-scroll">
                About Us
              </a>
            </li>
            <li>
              <a href="#" className="page-scroll">
                Our Product
              </a>
            </li>
            {/* <li>
              <a href="/en-privacy-policy" className="page-scroll">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/en-user-agreement" className="page-scroll">
                User Agreement
              </a>
            </li> */}
            {/* Sample dropdown */}
            {/* <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Language
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="/cn">中文</a>
                <a class="dropdown-item" href="/pt">PORTUGESE</a>
              </div>
            </li> */}
          </ul>
          
        </div>
        
      </div>
    </nav>
  );
};
