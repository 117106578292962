// import { useState } from "react";
// import emailjs from "emailjs-com";
import React from "react";

export const Contact = (props) => {
  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <p>
            Copyright &copy; 2024 ,{" "}
            <a href="https://okscore.ai/" rel="nofollow">
              OkScore.ai ,
            </a>
            All Rights Reserved
          </p>
          <p>
            <a href="/cn-terms-and-conditions">条款和条件</a> |&nbsp;
            <a href="/cn-user-agreement">用户协议</a> |&nbsp;
            <a href="/cn-privacy-policy">隐私政策</a>
          </p>
        </div>
      </div>
    </div>
  );
};
